// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card {\n\tmix-blend-mode: normal;\n\tborder-radius: 20px;\n\toutline: 2px solid #f4f4f5;\n}\n\n.card-container {\n\tpadding: 30px;\n\tpadding-bottom: 5px;\n}\n\n.card-header {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.card-icon {\n\tfont-size: 15px;\n\tcolor: var(--tertiary-color);\n}\n\n.card-title {\n\tcolor: var(--secondary-color);\n\tfont-size: 14px;\n\tpadding-left: 20px;\n\tfont-weight: 650;\n}\n\n.card-body {\n\tpadding-top: 40px;\n\tfont-size: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/styles/card.css"],"names":[],"mappings":"AAEA;CACC,sBAAsB;CACtB,mBAAmB;CACnB,0BAA0B;AAC3B;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,4BAA4B;AAC7B;;AAEA;CACC,6BAA6B;CAC7B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;CACjB,eAAe;AAChB","sourcesContent":["@import \"../../../data/styles.css\";\n\n.card {\n\tmix-blend-mode: normal;\n\tborder-radius: 20px;\n\toutline: 2px solid #f4f4f5;\n}\n\n.card-container {\n\tpadding: 30px;\n\tpadding-bottom: 5px;\n}\n\n.card-header {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.card-icon {\n\tfont-size: 15px;\n\tcolor: var(--tertiary-color);\n}\n\n.card-title {\n\tcolor: var(--secondary-color);\n\tfont-size: 14px;\n\tpadding-left: 20px;\n\tfont-weight: 650;\n}\n\n.card-body {\n\tpadding-top: 40px;\n\tfont-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
