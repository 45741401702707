// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".social,\n.email-wrapper {\n\tdisplay: flex;\n\tpadding-bottom: 20px;\n}\n\n.social a,\n.email-wrapper a {\n\ttext-decoration: none;\n}\n\n.social-icon {\n\tfont-size: 15px;\n\tcolor: var(--secondary-color);\n}\n\n.social-text {\n\tcolor: var(--secondary-color);\n\tpadding-left: 30px;\n\tmargin-top: -20px;\n\tfont-weight: 600;\n\tfont-size: 14px;\n}\n\n.email::before {\n\tcontent: \"\";\n\tdisplay: block;\n\tpadding-top: 20px;\n\tborder-top: 3px solid #f4f4f5;\n\tmargin-top: 20px;\n}\n\n.email-wrapper {\n\tpadding-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/about/styles/socials.css"],"names":[],"mappings":"AACA;;CAEC,aAAa;CACb,oBAAoB;AACrB;;AAEA;;CAEC,qBAAqB;AACtB;;AAEA;CACC,eAAe;CACf,6BAA6B;AAC9B;;AAEA;CACC,6BAA6B;CAC7B,kBAAkB;CAClB,iBAAiB;CACjB,gBAAgB;CAChB,eAAe;AAChB;;AAEA;CACC,WAAW;CACX,cAAc;CACd,iBAAiB;CACjB,6BAA6B;CAC7B,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":["@import \"../../../data/styles.css\";\n.social,\n.email-wrapper {\n\tdisplay: flex;\n\tpadding-bottom: 20px;\n}\n\n.social a,\n.email-wrapper a {\n\ttext-decoration: none;\n}\n\n.social-icon {\n\tfont-size: 15px;\n\tcolor: var(--secondary-color);\n}\n\n.social-text {\n\tcolor: var(--secondary-color);\n\tpadding-left: 30px;\n\tmargin-top: -20px;\n\tfont-weight: 600;\n\tfont-size: 14px;\n}\n\n.email::before {\n\tcontent: \"\";\n\tdisplay: block;\n\tpadding-top: 20px;\n\tborder-top: 3px solid #f4f4f5;\n\tmargin-top: 20px;\n}\n\n.email-wrapper {\n\tpadding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
